var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"set-schedule-modal",attrs:{"visible":_vm.visible,"maskClosable":false,"title":_vm.editType === 'edit' ? _vm.$t('calendar.editSchedule') : _vm.$t('calendar.addSchedule'),"width":500,"centered":"","confirmLoading":_vm.loading},on:{"close":_vm.closeModal,"ok":_vm.confirmSchedule,"cancel":_vm.closeModal}},[_c('a-form',{attrs:{"form":_vm.scheduleForm}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.campus')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'campusIds',
          {
            initialValue: [_vm.campusId],
            rules: [
              {
                required: true,
                message: _vm.$t('homework.selectCourse'),
              } ],
          } ]),expression:"[\n          'campusIds',\n          {\n            initialValue: [campusId],\n            rules: [\n              {\n                required: true,\n                message: $t('homework.selectCourse'),\n              },\n            ],\n          },\n        ]"}],staticClass:"filter-input",attrs:{"showSearch":"","optionFilterProp":"children","placeholder":_vm.$t('common.select'),"mode":"multiple"}},_vm._l((_vm.campuses),function(item){return _c('a-select-option',{key:item.key},[_vm._v(_vm._s(_vm.locale === 'zh' ? item.value : item.enValue || item.value))])}),1)],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'description',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('tips.enterName'),
              } ],
          } ]),expression:"[\n          'description',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('tips.enterName'),\n              },\n            ],\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('common.enterContent'),"max-length":50}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.date')}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'date',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('tips.selectDate'),
              } ],
          } ]),expression:"[\n          'date',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('tips.selectDate'),\n              },\n            ],\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"showTime":false,"disabledDate":_vm.disabledScheduleDate,"getCalendarContainer":function () { return _vm.$document.body; }},on:{"change":_vm.validateTime}})],1),_c('a-form-item',{staticClass:"form-item form-item-no-margin schedule-time",attrs:{"colon":false,"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"time-label"},[_c('span',{staticStyle:{"flex-shrink":"0","margin-right":"16px"}},[_vm._v(_vm._s(_vm.$t('calendar.time'))+":")]),_c('a-checkbox',{attrs:{"checked":_vm.allDay},on:{"change":function($event){_vm.allDay = !_vm.allDay}}},[_vm._v(_vm._s(_vm.$t('calendar.allDay')))])],1)]},proxy:true}])},[_c('div',{staticClass:"time-range"},[_c('a-form-item',{staticClass:"form-item"},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              "startTime",
              {
                initialValue: _vm.moment()
                  .hour(0)
                  .minute(0),
                rules: [
                  {
                    validator: _vm.validateTimeStart,
                  } ],
              } ]),expression:"[\n              `startTime`,\n              {\n                initialValue: moment()\n                  .hour(0)\n                  .minute(0),\n                rules: [\n                  {\n                    validator: validateTimeStart,\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.allDay,"format":"HH:mm","placeholder":"","getPopupContainer":function () { return _vm.$document.body; }}})],1),_c('span',[_vm._v("~")]),_c('a-form-item',{staticClass:"form-item"},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              "endTime",
              {
                initialValue: _vm.moment()
                  .hour(23)
                  .minute(59),
                rules: [
                  {
                    validator: _vm.validateTimeEnd,
                  } ],
              } ]),expression:"[\n              `endTime`,\n              {\n                initialValue: moment()\n                  .hour(23)\n                  .minute(59),\n                rules: [\n                  {\n                    validator: validateTimeEnd,\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.allDay,"getPopupContainer":function () { return _vm.$document.body; },"format":"HH:mm","placeholder":""}})],1)],1)]),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('calendar.location')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['location', {}]),expression:"['location', {}]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('common.enterContent'),"max-length":50}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('calendar.remark')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remark',
          {
            rules: [
              {
                max: 2000,
                message: _vm.$t('common.textLimit2000'),
              } ],
          } ]),expression:"[\n          'remark',\n          {\n            rules: [\n              {\n                max: 2000,\n                message: $t('common.textLimit2000'),\n              },\n            ],\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"type":"textarea","autoSize":{ minRows: 3, maxRows: 5 },"placeholder":_vm.$t('common.enterContent')}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('calendar.status')}},[_c('a-switch',{model:{value:(_vm.forParent),callback:function ($$v) {_vm.forParent=$$v},expression:"forParent"}}),_c('span',{staticStyle:{"margin-left":"16px"}},[_vm._v(_vm._s(_vm.$t('calendar.visibleToParent')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
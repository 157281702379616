
















































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import moment from 'moment'
import { CalendarController, DropDownController } from '@/services/request.service'
import cloneDeep from 'lodash/cloneDeep'
import filterGroup from '@/components/filterGroup'
import SetScheduleModal from './components/SetScheduleModal.vue'

@Component({
  components: {
    FullCalendar,
    filterGroup,
    SetScheduleModal,
  },
})
export default class Calendar extends Vue {
  private loading = false
  private calendarPlugins = [dayGridPlugin]
  private parentView = false
  private calendarTime = moment()
  private createScheduleVisible = false
  private scheduleVisible = false
  private setDateVisible = false
  private eventList: Array<any> = []
  private currentCalendar: any = {}
  private firstSemStartDate = moment()
  private firstSemEndDate = moment()
  private secondSemStartDate = moment()
  private secondSemEndDate = moment()
  private setDateTime: Array<any> = []
  private setDateCampuses: any = []
  private setDateType = '1708'
  private holidayName = ''
  private document = document
  private campuses: any = []
  private filter = {
    campusId: undefined,
  }
  private schedule: any = {
    eventId: 0,
    date: undefined,
    description: '',
    startTime: moment().startOf('day'),
    endTime: moment().endOf('day'),
    allDay: true,
    location: '',
    remark: '',
    forParent: false,
    campusIds: [],
  }
  private scheduleLoading = false
  private dateLoading = false
  private startDay = moment()
  private lastDay = moment()
  private scheduleType = ''

  private moment = moment

  private get roleType(): string {
    return this.$store.state.roleType
  }

  private get operationRight(): boolean {
    const right = (this.campuses.filter(item => item.key === this.filter.campusId)[0] || {})
      .extraValue
    return right && !this.parentView
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  @Watch('locale')
  private onLocaleChange(): void {
    this.getCalendar()
  }

  private changeStartTime(stime): void {
    const time = moment(stime)
    const endTime = moment(this.schedule.endTime)
    if (endTime) {
      if (
        time.startOf('minute').isAfter(endTime.startOf('minute')) ||
        time.startOf('minute').isSame(endTime.startOf('minute'))
      ) {
        this.$set(this.schedule, 'startTime', endTime.subtract(1, 'm').startOf('minute'))
      } else {
        this.$set(this.schedule, 'startTime', time.startOf('minute'))
      }
    } else {
      this.$set(this.schedule, 'startTime', time.startOf('minute'))
    }
  }

  private changeEndTime(etime): void {
    const time = moment(etime)
    const startTime = moment(this.schedule.startTime)
    if (startTime) {
      if (
        time.startOf('minute').isBefore(startTime.startOf('minute')) ||
        time.startOf('minute').isSame(startTime.startOf('minute'))
      ) {
        this.$set(this.schedule, 'endTime', startTime.add(1, 'm').startOf('minute'))
      } else {
        this.$set(this.schedule, 'endTime', time.startOf('minute'))
      }
    } else {
      this.$set(this.schedule, 'endTime', time.startOf('minute'))
    }
  }

  private disabledSetDate(current) {
    return (
      current && (current < moment().endOf('day') || current > moment(this.lastDay).endOf('day'))
    )
  }

  private disabledScheduleDate(current) {
    return (
      current &&
      (current < moment(this.startDay).startOf('day') ||
        current > moment(this.lastDay).endOf('day'))
    )
  }

  private columnHeaderHtml(date): any {
    return (
      '<span style="line-height: 52px;">' +
      this.$t('weekday[' + moment(date).day() + ']') +
      '</span>'
    )
  }

  private prevMonth(): void {
    const calendar = this.$refs.fullCalendar as any
    let calendarApi = calendar.getApi()
    calendarApi.prev()
    const date = calendarApi.getDate()
    this.calendarTime = moment(date)
    this.getCalendar()
  }

  private nextMonth(): void {
    const calendar = this.$refs.fullCalendar as any
    let calendarApi = calendar.getApi()
    calendarApi.next()
    const date = calendarApi.getDate()
    this.calendarTime = moment(date)
    this.getCalendar()
  }

  private changeMonth(value): void {
    const calendar = this.$refs.fullCalendar as any
    let calendarApi = calendar.getApi()
    calendarApi.gotoDate(value.valueOf())
    this.getCalendar()
  }

  private dayRender(dayRenderInfo): void {
    const currentCalendar = this.currentCalendar
    const date = moment(dayRenderInfo.date)
      .startOf('day')
      .valueOf()
    if (currentCalendar[date]) {
      dayRenderInfo.el.insertAdjacentHTML(
        'beforeend',
        '<span class="fc-content" aria-hidden="true" style="color: ' +
          currentCalendar[date].color +
          ';" title="' +
          currentCalendar[date].title +
          '">' +
          currentCalendar[date].title +
          '</span>'
      )
    }
  }

  private getCalendar(): void {
    if (this.loading || !this.filter.campusId) return
    this.loading = true
    const date = this.calendarTime.valueOf()
    const calendar = this.$refs.fullCalendar as any
    const calendarApi = calendar.getApi()
    const view = calendarApi.view
    const activeStart = moment(view.activeStart)
    const activeEnd = moment(view.activeEnd)
    CalendarController.getCalendarByMonth(
      activeStart.valueOf(),
      activeEnd.valueOf(),
      date,
      this.parentView,
      this.filter.campusId as any
    )
      .then(res => {
        this.eventList = res.data.eventList.map(event => {
          return {
            id: event.eventId,
            title: event.description,
            start: moment(event.startDate).format('YYYY-MM-DD'),
            end: moment(event.endDate)
              .add(1, 'd')
              .format('YYYY-MM-DD'),
            allDay: event.allDay,
          }
        })
        this.firstSemStartDate = moment(res.data.firstSemStartDate)
        this.firstSemEndDate = moment(res.data.firstSemEndDate)
        this.secondSemStartDate = moment(res.data.secondSemStartDate)
        this.secondSemEndDate = moment(res.data.secondSemEndDate)
        this.startDay = moment(res.data.startDate)
        this.lastDay = moment(res.data.lastDate)
        let currentCalendar = {}
        res.data.calendarDays.forEach(item => {
          if (item.type === '1708') {
            currentCalendar[
              moment(item.date)
                .startOf('day')
                .valueOf()
            ] = {
              title: this.$t('calendar.workingDay'),
              color: '#fdc33a',
            }
          } else if (item.type === '1709') {
            currentCalendar[
              moment(item.date)
                .startOf('day')
                .valueOf()
            ] = {
              title: item.description || this.$t('calendar.holiday'),
              // color: '#ed5075',
              color: '#cccccc',
            }
          } else if (item.type === '1712') {
            currentCalendar[
              moment(item.date)
                .startOf('day')
                .valueOf()
            ] = {
              title: this.$t('calendar.semesterStartDate'),
              color: '#26b889',
            }
          } else if (item.type === '1713') {
            currentCalendar[
              moment(item.date)
                .startOf('day')
                .valueOf()
            ] = {
              title: this.$t('calendar.semesterEndDate'),
              color: '#26b889',
            }
          } else if (item.type === '1714') {
            currentCalendar[
              moment(item.date)
                .startOf('day')
                .valueOf()
            ] = {
              title: this.$t('calendar.holiday'),
              color: '#cccccc',
            }
          } else if (item.type === '1715') {
            currentCalendar[
              moment(item.date)
                .startOf('day')
                .valueOf()
            ] = {
              title: this.$t('calendar.holiday'),
              color: '#cccccc',
            }
          } else if (item.type === '1716') {
            currentCalendar[
              moment(item.date)
                .startOf('day')
                .valueOf()
            ] = {
              title: this.$t('calendar.holiday'),
              color: '#cccccc',
            }
          }
        })
        this.currentCalendar = cloneDeep(currentCalendar)
        calendarApi.render()
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
        const currentCalendar = this.currentCalendar
        for (let i in currentCalendar) {
          if (currentCalendar[i]) {
            const color = this.currentCalendar[i].color
            const timestamp = parseInt(i, 10)
            const date = moment(timestamp).format('YYYY-MM-DD')
            if (color === '#cccccc') {
              let dom = document.querySelector(
                'td.fc-day-top[data-date="' + date + '"] span'
              ) as HTMLElement
              if (dom) {
                dom.style.color = '#666666'
              }
            } else {
              let dom = document.querySelector(
                'td.fc-day-top[data-date="' + date + '"] span'
              ) as HTMLElement
              if (dom) {
                dom.style.color = '#fff'
                dom.style.backgroundColor = color
              }
            }
          }
        }
      })
  }

  private getBetween(start, end): Array<any> {
    let startTime = start.valueOf()
    let endTime = end.valueOf()
    let date = [] as any
    for (; startTime <= endTime; startTime += 86400000) {
      const temp = startTime
      date.push(temp.valueOf())
    }
    return date
  }

  private setDate(): void {
    this.setDateVisible = true
    this.setDateTime = []
    this.setDateCampuses = [this.filter.campusId]
    this.setDateType = '1708'
    this.holidayName = ''
  }

  private confirmSetDate(): void {
    this.dateLoading = true
    if (this.setDateTime.length && this.setDateType && this.setDateCampuses.length) {
      const dayType = {
        startDate: moment(this.setDateTime[0])
          .startOf('day')
          .valueOf(),
        endDate: moment(this.setDateTime[1])
          .startOf('day')
          .valueOf(),
        type: this.setDateType,
        description: this.setDateType === '1709' ? this.holidayName : '',
        campusIds: this.setDateCampuses,
      }
      CalendarController.setDayType(dayType)
        .then(res => {
          this.$message.success(this.$tc('common.setSuccess'))
          this.setDateVisible = false
          this.getCalendar()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.dateLoading = false
          this.getCalendar()
        })
    } else {
      this.$message.error(this.$tc('common.blankOptions'))
      this.dateLoading = false
    }
  }

  private addSchedule(): void {
    this.createScheduleVisible = true
    this.scheduleType = 'add'
    this.schedule = {
      eventId: 0,
      startDate: null,
      endDate: null,
      description: '',
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      allDay: true,
      location: '',
      remark: '',
      forParent: false,
    }
  }

  private getDropdownInfo(): void {
    DropDownController.getCampusList().then(res => {
      this.campuses = res.data
      if (this.campuses.length) {
        this.filter.campusId = this.campuses[0].key
        this.getCalendar()
      }
    })
  }

  private created() {
    this.getDropdownInfo()
  }

  private confirmSchedule(): void {
    this.scheduleLoading = true
    if (this.schedule.date && this.schedule.date.length && this.schedule.description) {
      if ((this.schedule.startTime && this.schedule.endTime) || this.schedule.allDay) {
        if (this.schedule.eventId) {
          const event = {
            ...this.schedule,
            date: undefined,
            startDate: moment(this.schedule.date[0])
              .startOf('day')
              .valueOf(),
            endDate: moment(this.schedule.date[1])
              .startOf('day')
              .valueOf(),
            startTime: this.schedule.allDay
              ? undefined
              : this.schedule.startTime
              ? this.schedule.startTime.format('HH:mm')
              : undefined,
            endTime: this.schedule.allDay
              ? undefined
              : this.schedule.endTime
              ? this.schedule.endTime.format('HH:mm')
              : undefined,
          }
          CalendarController.updateEvent(event)
            .then(res => {
              this.$message.success(this.$tc('common.saveSuccess'))
              this.createScheduleVisible = false
              this.getCalendar()
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              this.scheduleLoading = false
              this.schedule = {
                eventId: 0,
                startDate: null,
                endDate: null,
                description: '',
                startTime: moment().startOf('day'),
                endTime: moment().endOf('day'),
                allDay: true,
                location: '',
                remark: '',
                forParent: false,
              }
              this.getCalendar()
            })
        } else {
          const event = {
            ...this.schedule,
            eventId: undefined,
            date: undefined,
            startDate: moment(this.schedule.date[0])
              .startOf('day')
              .valueOf(),
            endDate: moment(this.schedule.date[1])
              .startOf('day')
              .valueOf(),
            startTime: this.schedule.allDay
              ? undefined
              : this.schedule.startTime
              ? this.schedule.startTime.format('HH:mm')
              : undefined,
            endTime: this.schedule.allDay
              ? undefined
              : this.schedule.endTime
              ? this.schedule.endTime.format('HH:mm')
              : undefined,
          }
          CalendarController.addEvent(event)
            .then(res => {
              this.$message.success(this.$tc('common.addSuccess'))
              this.createScheduleVisible = false
              this.getCalendar()
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              this.scheduleLoading = false
              this.schedule = {
                eventId: 0,
                startDate: null,
                endDate: null,
                description: '',
                startTime: moment().startOf('day'),
                endTime: moment().endOf('day'),
                allDay: true,
                location: '',
                remark: '',
                forParent: false,
              }
              this.getCalendar()
            })
        }
      } else {
        this.$message.error(this.$tc('common.blankOptions'))
        this.scheduleLoading = false
      }
    } else {
      this.$message.error(this.$tc('common.blankOptions'))
      this.scheduleLoading = false
    }
  }

  private setScheduleSuccess(): void {
    this.getCalendar()
  }

  private selectEvent(info): void {
    this.scheduleVisible = true
    const eventId = info.event.id
    CalendarController.getEventInfo(eventId)
      .then(res => {
        const schedule = {
          ...res.data,
          date: [moment(res.data.startDate), moment(res.data.endDate)],
          startTime: res.data.startTime
            ? moment(moment().format('YYYY-MM-DD') + ' ' + res.data.startTime)
            : null,
          endTime: res.data.endTime
            ? moment(moment().format('YYYY-MM-DD') + ' ' + res.data.endTime)
            : null,
          campusIds: res.data.campuses.map(item => item.campusId),
        }
        this.schedule = schedule
      })
      .catch(err => {
        console.error(err)
      })
  }

  private editSchedule(): void {
    this.scheduleVisible = false
    this.scheduleType = 'edit'
    this.createScheduleVisible = true
  }

  private deleteSchedule(): void {
    const eventId = this.schedule.eventId
    this.$confirm({
      title: this.$t('calendar.deleteConfirm') as string,
      onOk: () => {
        CalendarController.deleteEvent(eventId)
          .then(res => {
            this.$message.success(this.$tc('common.deleteSuccess'))
            this.scheduleVisible = false
            this.getCalendar()
          })
          .catch(err => {
            console.error(err)
          })
      },
    })
  }

  private enterParentView(): void {
    this.parentView = true
    this.getCalendar()
  }

  private exitParentView(): void {
    this.parentView = false
    this.getCalendar()
  }
}









































































































































































import { Vue, Component, Prop, Model, Emit, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { CalendarController } from '@/services/request.service'

const operations = {
  edit: function(request) {
    return CalendarController.updateEvent(request)
  },
  add: function(request) {
    return CalendarController.addEvent(request)
  },
}

@Component
export default class SetScheduleModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() public readonly startDay!: any
  @Prop() public readonly lastDay!: any
  @Prop() public readonly campuses!: any
  @Prop() public readonly schedule!: any
  @Prop() public readonly editType!: any
  @Prop() public readonly campusId!: any
  private allDay: boolean = true
  private scheduleForm: any
  private loading: boolean = false
  private forParent: boolean = false
  private moment = moment

  private get locale(): string {
    return this.$store.state.locale
  }

  private beforeCreate(): void {
    this.scheduleForm = this.$form.createForm(this)
  }

  private confirmSchedule(): void {
    this.loading = true
    this.scheduleForm.validateFields((err: any, values: any) => {
      if (err) {
        this.loading = false
        return
      } else {
        this.loading = true
        const condition = {
          ...values,
          date: undefined,
          startDate: moment(values.date[0])
            .startOf('day')
            .valueOf(),
          endDate: moment(values.date[1])
            .startOf('day')
            .valueOf(),
          startTime: this.allDay ? undefined : values.startTime.format('HH:mm'),
          endTime: this.allDay ? undefined : values.endTime.format('HH:mm'),
          allDay: this.allDay,
          forParent: this.forParent,
          eventId: this.editType === 'edit' ? this.schedule.eventId : undefined,
          campusIds: values.campusIds,
        }
        operations[this.editType](condition)
          .then(res => {
            this.$message.success(this.$tc('common.saveSuccess'))
            this.closeModal()
            this.$emit('success')
          })
          .catch(err => console.log(err))
          .finally(() => (this.loading = false))
      }
    })
  }

  private disabledScheduleDate(current) {
    const { lastDay, startDay } = this
    return (
      current &&
      (current < moment(startDay).startOf('day') || current > moment(lastDay).endOf('day'))
    )
  }

  private validateTime(): void {
    this.$nextTick(() => {
      this.scheduleForm.validateFields(
        ['startTime', 'endTime'],
        { force: true },
        (err: any, values: any) => {
          if (err) {
            return
          }
        }
      )
    })
  }

  private validateTimeStart(rule, value, cb): void {
    const form = this.scheduleForm
    if (this.allDay) {
      cb()
    }
    if (!value) {
      cb(this.$t('tips.selectTime'))
    } else {
      let end = form.getFieldValue('endTime')
      let date = form.getFieldValue('date')

      if (end && value.isAfter(end) && date && date.length && !date[1].diff(date[0], 'day')) {
        cb(this.$t('tips.timeStartAfterEnd'))
      } else {
        cb()
      }
    }
  }

  private validateTimeEnd(rule, value, cb): void {
    const form = this.scheduleForm
    if (this.allDay) {
      cb()
    }
    if (!value) {
      cb(this.$t('tips.selectTime'))
    } else {
      let start = form.getFieldValue('startTime')
      let date = form.getFieldValue('date')

      if (start && value.isBefore(start) && date && date.length && !date[1].diff(date[0], 'day')) {
        cb(this.$t('tips.timeEndBeforeStart'))
      } else {
        cb()
      }
    }
  }

  @Emit('change')
  private closeModal(): boolean {
    this.scheduleForm.resetFields()
    this.allDay = true
    this.forParent = false
    return false
  }

  @Watch('visible', { immediate: true, deep: true })
  private onType(val): void {
    if (this.editType === 'edit' && this.visible) {
      this.$nextTick(() => {
        const { schedule } = this
        this.scheduleForm.setFieldsValue({
          description: schedule.description,
          date: [
            schedule.startDate ? moment(schedule.startDate) : moment(),
            schedule.endDate ? moment(schedule.endDate) : moment(),
          ],
          startTime: schedule.startTime
            ? moment(schedule.startTime)
            : moment()
                .hour(0)
                .minute(0),
          endTime: schedule.endTime
            ? moment(schedule.endTime)
            : moment()
                .hour(23)
                .minute(59),
          location: schedule.location,
          remark: schedule.remark,
          campusIds: schedule.campusIds,
          // campusIds: schedule.campuses.map(item => item.campusId)
        })
        this.allDay = schedule.allDay
        this.forParent = schedule.forParent
      })
    }
  }
}
